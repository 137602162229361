<template>
  <div class="researches">
    <Loader v-if="isLoading" />
    <div v-else-if="data && data.researches && data.researches.length > 0">
      <div class="vx-row">
        <div
          class="vx-col w-full sm:w-1/2 lg:w-1/3 mb-base"
          v-for="(research, index) in data.researches"
          :key="`${index}-research`"
        >
          <div class="vx-card">
            <!---->
            <div class="vx-card__collapsible-content vs-con-loading__container">
              <div class="vx-card__body">
                <img
                  alt="content-img"
                  class="responsive rounded-lg research-image"
                  v-if="research.figure"
                  :src="research.figure.figure"
                />
                <div class="my-6">
                  <div class="flex justify-between">
                    <h5 class="mb-2" v-if="$l.isPtBr">
                      {{ research.titlePtBr }}
                    </h5>
                    <h5 class="mb-2" v-if="$l.isEnUs">
                      {{ research.titleEnUs }}
                    </h5>
                    <h5 class="mb-2" v-if="$l.isEsEs">
                      {{ research.titleEsEs }}
                    </h5>
                    <!-- <div class="mb-2">
                      <ReportReliability
                        :reliability="reportVariant.report.reliability"
                      />
                    </div> -->
                  </div>
                  <!-- <h5 class="mb-2">Perfil Guerreiro</h5> -->
                </div>
                <!-- <div class="research-description">
                  <p v-if="$l.isPtBr">{{ reportVariant.resultPtBr }}</p>
                  <p v-if="$l.isEnUs">{{ reportVariant.resultEnUs }}</p>
                  <p v-if="$l.isEsEs">{{ reportVariant.resultEsEs }}</p>
                </div> -->
                <div class="mb-4">
                  <div class="flex justify-between">
                    <small
                      class="font-semibold"
                      v-if="research.volunteersPercent >= 100"
                    >
                      {{ $l.get("researches.complete-sampling") }}
                    </small>
                    <small class="font-semibold" v-else>
                      {{ $l.get("researches.current-sampling") }}
                    </small>
                    <small class="text-grey">
                      {{ $l.get("researches.exclusive-report") }}
                    </small>
                  </div>
                  <div
                    class="vs-progress--background block mt-1 shadow-md vs-progress-primary"
                    style="height: 5px;"
                  >
                    <div
                      class="vs-progress--foreground"
                      :style="
                        `width: ${Math.trunc(research.volunteersPercent)}%;`
                      "
                    ></div>
                    <!---->
                  </div>
                </div>
                <div class="flex justify-between flex-wrap">
                  <div></div>
                  <vs-button
                    v-if="research.userIsVolunteer"
                    color="success"
                    type="border"
                    @click="onResearchParticipate(research)"
                    >{{ $l.get("researches.participate") }}</vs-button
                  >
                  <vs-button
                    v-else
                    color="primary"
                    @click="onResearchParticipate(research)"
                    >{{ $l.get("researches.participate") }}</vs-button
                  >
                </div>
              </div>
              <!---->
            </div>
            <div
              class="vx-card__code-container collapsed"
              style="max-height: 0px; display: none;"
            >
              <div class="code-content">
                <pre
                  class="language-markup"
                ><code class="language-markup"></code></pre>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div
      style="text-align: center; color: #AAAAAA !important"
      v-else-if="data && data.researches && data.researches.length == 0"
    >
      <feather-icon
        icon="LayersIcon"
        style="margin-top: 50px; margin-bottom: 10px"
      />
      <h3 style="color: #AAAAAA !important">
        {{ $l.get("researches.empty-list") }}
      </h3>
    </div>
  </div>
</template>

<script>
import SelectParticipateInteractor from "@/business/researches/select-participate";
import InitResearchesScreenInteractor from "@/business/researches/init-researches-screen";
import ResearchesScreenController from "@/adapters/controllers/screen-researches";
import Loader from "@/application/components/Loader";

export default {
  screen_name: "researches",
  components: { Loader },
  data() {
    return {
      controller: null,
      interactors: {
        selectParticipate: null,
        initResearchesScreen: null
      },
      data: null,
      isLoading: false
    };
  },
  beforeMount() {
    this.controller = this.$injector.get(ResearchesScreenController);

    //{ INTERACTORS
    this.interactors.initResearchesScreen = this.$injector.get(
      InitResearchesScreenInteractor
    );
    this.interactors.selectParticipate = this.$injector.get(
      SelectParticipateInteractor
    );
    //} INTERACTORS

    this.controller.setData = this.setData;
    this.controller.getData = this.getData;
    this.controller.setAsLoading = this.setAsLoading;
    this.controller.setAsLoaded = this.setAsLoaded;
  },
  mounted() {
    this.interactors.initResearchesScreen.handle();
  },
  methods: {
    setData(data) {
      this.data = data;
    },
    getData() {
      return this.data;
    },
    setAsLoading() {
      this.isLoading = true;
    },
    setAsLoaded() {
      this.isLoading = false;
    },
    onResearchParticipate(research) {
      this.interactors.selectParticipate.handle(research.id);
    }
  }
};
</script>

<style lang="scss">
#research {
  .research-image {
    height: 300px;
    object-fit: cover;
  }

  .research-description {
    color: #999999;
    margin-top: -10px;
    margin-bottom: 15px;
  }

  .overlay-card {
    .vx-card__collapsible-content {
      max-height: 300px;
      min-height: 300px;
    }
  }
  .greet-user {
    position: relative;

    .decore-left {
      position: absolute;
      left: 0;
      top: 0;
    }
    .decore-right {
      position: absolute;
      right: 0;
      top: 0;
    }
  }

  @media (max-width: 576px) {
    .decore-left,
    .decore-right {
      width: 140px;
    }
  }
}
</style>
